import axiosInstance from "../axiosInstanceService/axiosInstance";

const state = {
    userlog: null,
    apicampistas: '/dasboard/load-campistas',
    campers: [],
  };
  
  const mutations = {
    setUser(state, userLog) {
      state.userLog = userLog;
    },
    clearUser(state) {
      state.userLog = null;
    },
    setCampers(state, campers) {
      state.campers = campers;
    }
  };
  
  const actions = {
    login({ commit }, user) {
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('clearUser');
    },
    async loadAllCampers({ state, commit }) {
      try {
        const response = await axiosInstance.get(state.apicampistas);
        const campers = response.data;
        
        commit('setCampers', campers);
      } catch (error) {
        console.error(error);
      }
    }
  };
  
  const getters = {
    isAuthenticated: state => !!state.userLog,
    getUser: state => state.userLog,
    getCampers: state => state.campers,
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };