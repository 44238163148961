<template>
    <header class="dashboard-header">
      <div class="content-header">
        <img :src="biblionero" alt="" class="logo-header">
        <h1 class="title-header">Biblionero</h1>
        <b-button @click="logout">Cerrar Sesión</b-button>
      </div>
  
    </header>
  </template>
  
  <script>
  import Biblionero from '../../assets/Biblionero.png';
  import {BButton} from 'bootstrap-vue-next';
  export default {
    name: 'DashboardHeader',
    components: {
      BButton
    },
    data() {
      return {
        biblionero: Biblionero,
      };
    },
    methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/');
    }
  }
  }
  </script>
  
  <style scoped>
  .dashboard-header {
    background-color: #333;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  .content-header{
    display: flex;
    justify-content: space-between;
    align-items:center;
  }
  
  .logo-header{
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .title-header{
    flex: 1;
    text-align: center;
  }

  /* Media query para hacer el header responsivo en pantallas pequeñas */
@media (max-width: 768px) {
  .content-header {
    flex-direction: column;
  }

  .logo-header {
    margin-bottom: 0.5rem;
  }

  .title-header {
    margin-bottom: 0.5rem;
  }

  .b-button {
    width: 100%;
  }
}
  
  </style>