import { createStore } from 'vuex';
import loadTypeId from './registerService/loadRegister';
import manageUserStore from './store/manageUserStore';
import emailStore from './store/emailStore';


const store = createStore({
  modules: {
    loadTypeId,
    user: manageUserStore,
    sendEmail: emailStore,
  },
  state: {
  },
  mutations: {
  },
  actions:{
  }
});

export default store;