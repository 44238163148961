<template>
  <div class="container d-flex flex-column align-items-center">
    <div class="row">
      <div class="col-md-12 text-center responsive-text">
        <h2>¡Bienvenid@!</h2>
        <h4 v-if="user && user.name && user.last_name">
          {{ capitalizeFirstLetters(user.name) + ' ' + capitalizeFirstLetters(user.last_name) }}
        </h4>
        <h4>Campamento Dosquebradas 2024</h4>
        <h4>Tiempo restante: {{ countdown }}</h4>
      </div>
    </div>
    <div v-if=" user && user.roll_id === 3">
      <b-button @click="sendEmail">
        Correo Bienvenida
      </b-button>
    </div>
    <div v-if=" user && user.roll_id === 3" class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header text-center">
            <h4 class="card-title">Campistas</h4>
            <input type="text" v-model="searchQuery" placeholder="Buscar..." class="form-control mt-2">
          </div>
          <div class="card-body">
            <b-table striped hover :items="paginatedCampers" :fields="fields" class="w-100">
              <template #cell(accion)="row">
                <b-button @click="openEditModal(row.item)">
                  <i class="fas fa-eye"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showEditModal" title="Información Campista" size="lg" hide-footer>
      <div>
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="id_usuario">ID Usuario:</label>
                <input type="text" id="id_usuario" v-model="localCamper.id_usuario" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="tipo_identificacion">Tipo de Identificación:</label>
                <input type="text" id="tipo_identificacion" v-model="localCamper.tipo_identificacion" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="identificacion">Identificación:</label>
                <input type="text" id="identificacion" v-model="localCamper.identificacion" class="form-control" disabled>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="nombre">Nombre:</label>
                <input type="text" id="nombre" v-model="localCamper.nombre" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="apellido">Apellido:</label>
                <input type="text" id="apellido" v-model="localCamper.apellido" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="correo">Correo:</label>
                <input type="email" id="correo" v-model="localCamper.correo" class="form-control" disabled>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="genero">Género:</label>
                <input type="text" id="genero" v-model="localCamper.genero" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="ciudad">Ciudad:</label>
                <input type="text" id="ciudad" v-model="localCamper.ciudad" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="congregacion">Congregación:</label>
                <input type="text" id="congregacion" v-model="localCamper.congregacion" class="form-control" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="se_congrega">Se Congrega:</label>
                <input type="text" id="se_congrega" v-model="localCamper.se_congrega" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="numero_telefono">Número de Teléfono:</label>
                <input type="text" id="numero_telefono" v-model="localCamper.numero_telefono" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="fecha_nacimiento">Fecha de Nacimiento:</label>
                <input type="text" id="fecha_nacimiento" v-model="localCamper.fecha_nacimiento" class="form-control" disabled>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="id_tutor">ID Tutor:</label>
                <input type="text" id="id_tutor" v-model="localCamper.id_tutor" class="form-control" disabled>
              </div>
              <div class="form-group">
                <label for="fecha_creado">Fecha Creado:</label>
                <input type="text" id="fecha_creado" v-model="localCamper.fecha_creado" class="form-control" disabled>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <div class="container-images d-flex justify-content-center">
      <img :src="imageUrl1" alt="Imagen 1" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BTable, BModal, BButton, BPagination } from 'bootstrap-vue-next';

export default {
  name: 'DashboardInit',
  components: {
    BTable,
    BModal,
    BButton,
    BPagination
  },
  data() {
    return {
      countdown: '',
      showEditModal: false,
      selectedCamper: {},
      localCamper: {},
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
      imageUrl1: 'https://s3-campamento.s3.us-east-2.amazonaws.com/campamento_3fd095b4.jpg',
      fields: [
        { key: 'id_usuario', label: 'ID Usuario' },
        { key: 'tipo_identificacion', label: 'Tipo de Identificación' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'apellido', label: 'Apellido' },
        { key: 'correo', label: 'Correo' },
        { key: 'genero', label: 'Género' },
        { key: 'ciudad', label: 'Ciudad' },
        { key: 'accion', label: 'Acción' }
      ],

    }
  },
  methods: {
    ...mapActions('user', ['loadAllCampers']),
    ...mapActions('sendEmail', ['sendWelcomeEmailAll']),

    capitalizeFirstLetters(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },

    updateCountdown() {
      const targetDate = new Date('2024-11-09T00:00:00');
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        this.countdown = '¡El evento ha comenzado!';
        clearInterval(this.countdownInterval);
        return;
      }

      const timeUnits = {
        days: 1000 * 60 * 60 * 24,
        hours: 1000 * 60 * 60,
        minutes: 1000 * 60,
        seconds: 1000
      };

      const days = Math.floor(difference / timeUnits.days);
      const hours = Math.floor((difference % timeUnits.days) / timeUnits.hours);
      const minutes = Math.floor((difference % timeUnits.hours) / timeUnits.minutes);
      const seconds = Math.floor((difference % timeUnits.minutes) / timeUnits.seconds);

      this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    },

    openEditModal(camper) {
      this.selectedCamper = { ...camper };
      this.localCamper = { ...camper };
      this.showEditModal = true;
    },

    handleSubmit() {
      // Lógica para guardar los cambios
      this.$emit('save', this.localCamper);
      this.showEditModal = false;
    },

    closeModal() {
      this.showEditModal = false;
    },

    sendEmail(){
      this.sendWelcomeEmailAll();
    }
  },

  computed: {
    ...mapGetters('user', ['getUser', 'getCampers']),
    user() {
      return this.getUser;
    },

    campers() {
      return this.getCampers;
    },

    filteredCampers() {
      if (!this.searchQuery) {
        return this.campers;
      }
      const query = this.searchQuery.toLowerCase();
      return this.campers.filter(camper => {
        return Object.values(camper).some(value =>
          String(value).toLowerCase().includes(query)
        );
      });
    },

    paginatedCampers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredCampers.slice(start, end);
    },

    totalRows() {
      return this.filteredCampers.length;
    }
  },

  mounted() {
    this.updateCountdown();
    this.countdownInterval = setInterval(this.updateCountdown, 1000);
    if(this.user && this.user.roll_id === 3){
      this.loadAllCampers();
    }
  },

  beforeUnmount() {
    clearInterval(this.countdownInterval);
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  max-width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

img {
  max-width: 80%;
  height: auto;
}

/* Media query para hacer la vista responsiva en pantallas pequeñas */
@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }

  .content-header {
    flex-direction: column;
    align-items: center;
  }

  .content-header img {
    margin-bottom: 10px;
  }

  .content-header h1 {
    margin-bottom: 10px;
  }

  .content-header b-button {
    width: 100%;
  }

  .card-header {
    text-align: center;
  }

  .card-body {
    padding: 0;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .row {
    flex-direction: column;
  }

  .col-md-4 {
    width: 100%;
  }

  .col-md-12 {
    width: 100%;
  }

  .b-table {
    font-size: 14px;
  }

  .b-pagination {
    justify-content: center;
  }

  .responsive-text h2,
  .responsive-text h4 {
    font-size: 1.2rem;
  }
}
</style>