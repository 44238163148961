import axiosInstance from "../axiosInstanceService/axiosInstance";

const state = {
    apiConsultarCorreos: 'consultar/correos-campistas',
    apiEnviarCorreos: 'enviar/bienvenida-campistas',
    emails: [],
  };
  
  const mutations = {
    
  };
  
  const actions = {
    async sendWelcomeEmailAll( { state } ) {
        try {
            await axiosInstance.get(state.apiConsultarCorreos);
        } catch (error) {
            console.error('ocurrio un error al enviar los correos electronicos ', error);
        }
    }
  };
  
  const getters = {
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };