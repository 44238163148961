<template>
    <aside class="dashboard-sidebar">
      <nav>
        <ul>
          <li><a href="#">Inicio</a></li>
          <li><a href="#">Reportes</a></li>
          <li @click="goToHorario"><a>Horario</a></li>
        </ul>
      </nav>
    </aside>
  </template>
  
  <script>
  export default {
    name: 'DashboardSidebar',
    components: {
    },

    methods: {
    goToHorario() {
      this.$router.push({ name: 'horario' });
    }
  }
  }
  </script>
  
  <style scoped>
  .dashboard-sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 1rem;
  }
  .dashboard-sidebar ul {
    list-style: none;
    padding: 0;
  }
  .dashboard-sidebar li {
    margin: 0.5rem 0;
  }
  .dashboard-sidebar a {
    text-decoration: none;
    color: #333;
  }

  /* Media query para ocultar el menú lateral en pantallas pequeñas */
@media (max-width: 768px) {
  .dashboard-sidebar {
    display: none;
  }
}
  </style>